export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBTdUpEpEcx-eie9LFU43B-DwTG9KImXXE",
    authDomain: "hides-4821e.firebaseapp.com",
    databaseURL: "https://hides-4821e-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "hides-4821e",
    storageBucket: "hides-4821e.appspot.com",
    messagingSenderId: "873480647084",
    appId: "1:873480647084:web:8d784d1ab8866f9b340a09",
    measurementId: "G-R1TPP8TMLJ"
  }
};
